import React, {useCallback, useEffect, useState} from 'react'
import {Header} from '../components/Header.tsx'
import {useTranslation} from 'react-i18next'
import {Button, Container, Grid, Modal, Typography} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'
import {useDropzone} from 'react-dropzone'
import {Theme} from '@mui/material/styles'
import {$api} from '../api/callApi'
import useNotification from '../components/useNotifcation'
import {ReactComponent as ErrorLogo} from '../assets/icon.file-error.svg'
import {ReactComponent as ArrowRight} from '../assets/icon.arrow-right.svg'
import {useManuallyLogout} from '../store/store'
import {useNavigate} from 'react-router-dom'
import stripJsonComments from 'strip-json-comments'

const useStyles = makeStyles((theme: Theme) => ({
    dragActive: {
        background: theme.colors.redLight,
    },
    modal: {
        width: 'auto',
        padding: '2em 2em 2em',
        marginTop: '25vh',
        background: theme.colors.greyLight,
    },
    rootDropzone: {
        width: '50vw',
        padding: '0em 2em',
        borderTop: '4px solid ' + theme.colors.red,
        background: theme.colors.greyLight,
    },
    rootCredentialsDropzone: {
        width: '50vw',
        padding: '0em 2em',
        marginTop: '2em',
        borderTop: '4px solid ' + theme.colors.red,
        background: theme.colors.greyLight,
    },
    button: {
        width: '15em',
        backgroundColor: theme.colors.grey + '!important',
        '&:hover': {
            backgroundColor: theme.colors.blue + '!important',
            color: 'white',
        },
    },
    errorIcon: {
        color: 'red',
        transform: 'rotate(45deg)',
        marginBottom: '-5px',
        marginRight: '5px',
    },
}))

export const SettingsPage = () => {
    const {t} = useTranslation()
    const theme: Theme = useTheme()
    const [openModal, setOpenModal] = useState(false)
    const [openCredentialsModal, setOpenCredentialsModal] = useState(false)

    const [openErrorFileTypeModal, setOpenErrorFileTypeModal] = useState(false)
    const [openWrongJsonErrorModal, setOpenWrongJsonErrorModal] =
        useState(false)
    const [openWrongCredentialNameModal, setOpenWrongCredentialNameModal] =
        useState(false)

    const [errorMessage, setErrorMessage] = useState('')

    const [file, setFile] = useState<null | File>(null)
    const [credentialsFile, setCredentialsFile] = useState<null | File>(null)

    const [isHovered, setIsHovered] = useState(false)
    const [isHoveredCredentials, setIsHoveredCredentials] = useState(false)

    const [tempFile, setTempFile] = useState<null | File>(null)
    const [tempCredentialsFile, setCredentialsTempFile] = useState<null | File>(
        null
    )


    const [currentExistingFile, setCurrentExistingFile] = useState('')
    const [currentExistingCredentialsFile, setCurrentExistingCredentialsFile] =
        useState('')

    //eslint-disable-next-line
    const [msg, sendNotification] = useNotification()
    //eslint-disable-next-line
    const [manuallyLogout, setManuallyLogout] = useManuallyLogout((s: any) => [
        s.manuallyLogout,
        s.setManuallyLogout,
    ])
    const navigate = useNavigate()
    const classes = useStyles()

    async function isJson(droppedFile: any, isMappingJson: boolean) {
        try {
            const reader = new FileReader()
            reader.readAsText(droppedFile[0], 'UTF-8')
            const data = await droppedFile[0].text()

            JSON.parse(stripJsonComments(data))
            if (isMappingJson) {
                setOpenModal(true)
                setTempFile(droppedFile)
            } else {
                setOpenCredentialsModal(true)
                setCredentialsTempFile(droppedFile)
            }
        } catch (e) {
            setErrorMessage("Die Datei muss eine valide JSON-Datei sein!")
            setOpenWrongJsonErrorModal(true)
        }
    }

    const onDrop = useCallback((droppedFile: any) => {
        if (droppedFile.length > 0) {
            isJson(droppedFile, true).then()
        } else {
            setOpenErrorFileTypeModal(true)
        }
    }, [])
    const handleCloseBackdropClick = () => {
        setOpenModal(false)
    }

    useEffect(() => {
        $api.mapping
            .getMapping()
            .then((resp) => {
                if (resp) {
                    setCurrentExistingFile(resp.data)
                }
            })
            .catch((error) => {
                window.location.href = '/login'
                localStorage.removeItem('userRole')
                // @ts-ignore
                localStorage.setItem('manuallyLogout', false)
            })
        $api.mapping
            .getCredentialsJson()
            .then((resp) => {
                if (resp) {
                    setCurrentExistingCredentialsFile(resp.data)
                }
            })
            .catch((error) => {
                window.location.href = '/login'
                localStorage.removeItem('userRole')
                // @ts-ignore
                localStorage.setItem('manuallyLogout', false)
            })
    }, [])

    function f(name, chars, subst) {
        return name.replace(
            new RegExp('(^[^\\.]{' + chars + '})[^\\.]+'),
            '$1' + subst
        )
    }

    function cropFilename(filename, length, subst) {
        let actual = f(filename, length, subst)
        return actual
    }

    // @ts-ignore
    const {
        getRootProps: getMappingJsonProps,
        getInputProps: getMappingInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        accept: {
            'application/json': ['.json'], // you need to specify into this array the extensions you want to accept
        },
        multiple: false,
    })

    const {
        getRootProps: getCredentialsJsonProps,
        getInputProps: getCredentialsInputProps,
        isDragActive: isDragActiveCredentials,
    } = useDropzone({
        onDrop: (droppedFile: any) => {
            if (droppedFile.length > 0) {
                if (droppedFile[0].name !== 'credentials.json') {
                    setOpenWrongCredentialNameModal(true)
                } else {
                    isJson(droppedFile, false, false).then()
                }
            } else {
                setOpenErrorFileTypeModal(true)
            }
        },
        accept: {
            'application/json': ['.json'],
        },
        multiple: false,
    })

    function getButtonName() {
        if (isHovered) {
            return 'JSON-Datei auswählen'
        }
        if (file !== null) {
            return cropFilename(file[0].name, 15, '..')
        }
        return currentExistingFile
    }

    function getButtonNameCredentials() {
        if (isHoveredCredentials) {
            return 'JSON-Datei auswählen'
        }
        if (credentialsFile !== null) {
            return cropFilename(credentialsFile[0].name, 15, '..')
        }
        return currentExistingCredentialsFile
    }


    function uploadJsonMapping() {
        // @ts-ignore
        $api.mapping
            .postMapping(tempFile)
            .then((resp) => {
                if (resp) {
                    setFile(tempFile)
                    setOpenModal(false)
                    //@ts-ignore
                    sendNotification({
                        msg: 'Mapping Datei erfolgreich hochgeladen',
                        variant: 'success',
                    })

                }
            })
            .catch((error) => {
                if (
                    error.response?.status === 401 ||
                    error.response?.status === 403 ||
                    error.message === "Network Error"
                ) {
                    localStorage.removeItem('userRole')
                    // @ts-ignore
                    localStorage.setItem('manuallyLogout', false)
                    setManuallyLogout(false)
                    navigate('/login')
                } else {
                    setErrorMessage(error.response.data.message)
                    setOpenWrongJsonErrorModal(true)
                    setOpenModal(false)
                }
            })
    }

    function updateCredentialsJson() {
        // @ts-ignore
        $api.mapping
            .postCredentialsJson(tempCredentialsFile)
            .then((resp) => {
                if (resp) {
                    setCredentialsFile(tempCredentialsFile)
                    setOpenCredentialsModal(false)
                    //@ts-ignore
                    sendNotification({
                        msg: 'Benutzerdaten erfolgreich hochgeladen',
                        variant: 'success',
                    })
                }
            })
            .catch((error) => {
                if (
                    error.response?.status === 401 ||
                    error.response?.status === 403 ||
                    error.message === "Network Error"
                ) {
                    localStorage.removeItem('userRole')
                    // @ts-ignore
                    localStorage.setItem('manuallyLogout', false)
                    setManuallyLogout(false)
                    navigate('/login')
                } else {
                    setErrorMessage(error.response.data.message)
                    setOpenWrongJsonErrorModal(true)
                    setOpenCredentialsModal(false)
                }
            })
    }

    useEffect(() => {
        document.title = `${t('Upload')}`
    }, [t])

    return (
        <>
            <Header/>
            <Container style={{overflowY: "auto", height: "80vh"}} maxWidth={false}>
                <Grid container justifyContent="center" mt={3} spacing={1} style={{marginTop: "0.1em"}}>
                    <h1 style={{fontWeight: 'lighter'}}>
                        Einstellung / Mapping
                    </h1>
                </Grid>
                <Grid container justifyContent="center" mt={3} spacing={1}>
                    <div
                        className={classes.rootDropzone}
                        {...getMappingJsonProps()}
                    >
                        <input {...getMappingInputProps()} />
                        <Grid
                            container
                            direction="row"
                            mb={5}
                            mt={0}
                            p={0}
                            justifyContent="left"
                            spacing={0}
                        >
                            <h4 style={{fontWeight: 'lighter'}}>
                                Mapping-Logik hochladen
                            </h4>
                            <Grid textAlign={'left'} item xs={12}>
                                <div
                                    className={
                                        isDragActive ? classes.dragActive : ''
                                    }
                                >
                                    {file !== null ||
                                    currentExistingFile !== '' ? (
                                        <Button
                                            className={classes.button}
                                            onMouseEnter={() => {
                                                setIsHovered(true)
                                            }}
                                            onMouseLeave={() => {
                                                setIsHovered(false)
                                            }}
                                        >
                                            {getButtonName()}
                                        </Button>
                                    ) : (
                                        <Button className={classes.button}>
                                            JSON-Datei auswählen
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        className={classes.rootCredentialsDropzone}
                        {...getCredentialsJsonProps()}
                    >
                        <input {...getCredentialsInputProps()} />
                        <Grid
                            container
                            direction="row"
                            mb={5}
                            mt={0}
                            p={0}
                            justifyContent="left"
                            spacing={0}
                        >
                            <h4 style={{fontWeight: 'lighter'}}>
                                Benutzerdaten-Datei hochladen
                            </h4>
                            <Grid textAlign={'left'} item xs={12}>
                                <div
                                    className={
                                        isDragActiveCredentials
                                            ? classes.dragActive
                                            : ''
                                    }
                                >
                                    {credentialsFile !== null ||
                                    currentExistingCredentialsFile !== '' ? (
                                        <Button
                                            className={classes.button}
                                            onMouseEnter={() => {
                                                setIsHoveredCredentials(true)
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoveredCredentials(false)
                                            }}
                                        >
                                            {getButtonNameCredentials()}
                                        </Button>
                                    ) : (
                                        <Button className={classes.button}>
                                            JSON-Datei auswählen
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Container>
            <Modal open={openModal} onClose={handleCloseBackdropClick}>
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <Typography
                                        variant="h5"
                                        style={{margin: '16px'}}
                                    >
                                        Sind Sie sicher, dass Sie die
                                        Mapping-Datei ersetzen möchten?
                                    </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid textAlign={'left'} item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenModal(false)
                                                }}
                                            >
                                                Abbrechen
                                            </Button>
                                        </Grid>
                                        <Grid textAlign={'right'} item xs={6}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.blue,
                                                    '&:hover': {
                                                        background:
                                                        theme.colors.red,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    uploadJsonMapping()
                                                }}
                                            >
                                                Ja, jetzt ersetzen{' '}
                                                <ArrowRight
                                                    style={{
                                                        marginTop: '0.2em',
                                                        marginLeft: '0.5em',
                                                        width: '1em',
                                                        height: '1em',
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal
                open={openCredentialsModal}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <Typography
                                        variant="h5"
                                        style={{margin: '16px'}}
                                    >
                                        Sind Sie sicher, dass Sie die
                                        Benutzerdaten-Datei ersetzen möchten?
                                    </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid textAlign={'left'} item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenCredentialsModal(
                                                        false
                                                    )
                                                }}
                                            >
                                                Abbrechen
                                            </Button>
                                        </Grid>
                                        <Grid textAlign={'right'} item xs={6}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.blue,
                                                    '&:hover': {
                                                        background:
                                                        theme.colors.red,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    updateCredentialsJson()
                                                }}
                                            >
                                                Ja, jetzt ersetzen
                                                <ArrowRight
                                                    style={{
                                                        marginTop: '0.2em',
                                                        marginLeft: '0.5em',
                                                        width: '1em',
                                                        height: '1em',
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>

            <Modal
                open={openErrorFileTypeModal}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        Die hochgeladene Datei muss eine
                                        JSON-Datei sein!
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenErrorFileTypeModal(
                                                        false
                                                    )
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal
                open={openWrongJsonErrorModal}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        {errorMessage.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenWrongJsonErrorModal(
                                                        false
                                                    )
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal
                open={openWrongCredentialNameModal}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        {
                                            "Die Datei muss den Namen 'credentials.json' besitzen!"
                                        }
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenWrongCredentialNameModal(
                                                        false
                                                    )
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>

        </>
    )
}
